<template>
  <Spinner v-if="isLoading === 'get'"/>

  <div v-else>
    <div class="between ai-c mb-30">
      <VButtonPageBack />

      <div class="d-flex gap-s">
        <VButton
          v-if="permissionUpdateCatalogCategory"
          text="Редактировать"
          icon="edit"
          size="sm"
          @click.native="toggleEdit"
        />
      </div>
    </div>

    <VForm
      class="gap-m"
      scroll-when-invalid
      @request="saveCatalogCategoriesTreeHandler"
    >
      <div class="box">
        <div class="between mb-30">
          <h1>Разделы каталога</h1>

          <VButton
            v-show="isEdit && permissionCreateCatalogCategory"
            text="Добавить 1 уровень"
            icon="create"
            size="sm"
            color="primary-sm"
            @click.native="addCatalogCategoryFirstLvl"
          />
        </div>

        <Container
          class="column gap-m"
          lock-axis="y"
          drag-handle-selector=".catalog-category-tree-field__draggable"
          drag-class="grabbing"
          :get-child-payload="getDropPayload"
          @drop="dropSectionHandler"
        >
          <Draggable
            v-for="(item, i) of categories"
            :key="item.id"
          >
            <CatalogCategoryTreeField
              v-model="categories[i]"
              :is-disabled="!isEdit"
              :has-button-create="permissionCreateCatalogCategory"
              :has-button-remove="permissionDestroyCatalogCategory"
              @remove-category="removeCatalogSectionHandler"
            />
          </Draggable>
        </Container>

        <SpinnerOverlay
          v-if="$store.state.isLoadingDnD"
          class="spinner-overlay--catalog bg--box-overlay"
        />
      </div>

      <ButtonGroup
        v-show="isEdit"
        textBtnConfirm="Сохранить"
        textBtnReject="Отмена"
        btn-size="l"
        :loading="$store.state.isSendingRequestPage"
        @reject="goToPage('Catalog')"
      />
    </VForm>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import ButtonGroup from '@/components/ui/ButtonGroup/ButtonGroup'
import CatalogCategoryTreeField from '@/components/views/Catalog/CatalogCategoryTreeField/CatalogCategoryTreeField'
import SpinnerOverlay from '@/components/ui/SpinnerOverlay'
import VButtonPageBack from '@/components/simple/button/VButtonPageBack'
import VButton from '@/components/simple/button/VButton'
import VForm from '@/components/Form/VForm'

import { mixinIsEdit } from '@/mixins/mixinIsEdit'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'

import CatalogCategory from '@/classes/model/Catalog/CatalogCategory'
import { mapActions, mapGetters } from 'vuex'
import { goToPage } from '@/utils/router'
import { refreshArr, spliceBy } from '@/utils/array/reactive'
import { applyDrag } from '@/utils/array'
import { isString } from '@/utils/string'
import { showAlertError, showAlertSuccess } from '@/utils/store/alert'

export default {
  name: 'CatalogCategoriesEdit',
  components: {
    SpinnerOverlay,
    ButtonGroup,
    CatalogCategoryTreeField,
    Container,
    Draggable,
    VButton,
    VButtonPageBack,
    VForm
  },
  mixins: [mixinIsEdit, mixinIsLoading],
  data: () => ({
    categories: []
  }),
  computed: {
    ...mapGetters('permission', {
      permissionCreateCatalogCategory: 'permissionCreateCatalogCategory',
      permissionUpdateCatalogCategory: 'permissionUpdateCatalogCategory',
      permissionDestroyCatalogCategory: 'permissionDestroyCatalogCategory'
    }),

    stateCategories () {
      return this.$store.state.catalog.categories.categoriesTree?.children || []
    }
  },
  async mounted () {
    try {
      this.startLoading('get')
      await this.getAllCategories()
      this.syncData()
    } finally {
      this.finishLoading()
    }
  },
  methods: {
    ...mapActions('catalog/categories', {
      getAllCategories: 'getAllCategories',
      updateAndSetMassCategory: 'updateAndSetMassCategory'
    }),
    goToPage,
    // TODO SCROLL TO INVALID
    async saveCatalogCategoriesTreeHandler () {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_PAGE')
        const payload = this.categories.map(this.mapCategoryRequest)

        await this.updateAndSetMassCategory(payload)

        this.syncData()
        this.syncSidebar()

        showAlertSuccess('Разделы сохранены')
        this.toggleEdit()
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_PAGE')
      }
    },

    syncSidebar () {
      this.$store.dispatch('menu/setSidebarMenu')
    },

    addCatalogCategoryFirstLvl () {
      this.categories.push(new CatalogCategory({
        sort: this.categories.length + 1
      }))
    },

    removeCatalogSectionHandler (id) {
      spliceBy(id, this.categories)
    },

    dropSectionHandler (dropResult) {
      try {
        this.$store.commit('START_IS_LOADING_DND')

        const result = applyDrag(this.categories, dropResult)

        refreshArr(this.categories, result)
      } finally {
        this.$store.commit('FINISH_IS_LOADING_DND')
      }
    },
    getDropPayload (idx) {
      return this.categories[idx]
    },

    syncData () {
      this.categories = this.stateCategories.map((item, i) => this.mapCategory(item, i, 1))
    },

    mapCategory (item, i, lvl) {
      return new CatalogCategory({
        ...item,
        lvl,
        sort: item.sort || (i + 1),
        children: item.children.map((item, i) => this.mapCategory(item, i, lvl + 1))
      })
    },
    mapCategoryRequest (item, i) {
      return {
        id: isString(item.id) ? null : item.id,
        name: item.name,
        parent_id: item.parent_id,
        sort: i,
        description: item.description,
        children_categories: item.children.map(this.mapCategoryRequest)
      }
    }
  }
}
</script>
